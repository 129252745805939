import React, { useState } from 'react'
import { array, string } from 'prop-types'
import { useQuery } from 'react-query'
import { styled } from '@mui/material'
import { getFromBrowserStorage } from '@helpers/storage'
import { getOrderHistoryByEmail } from '@services/order-status'
import { useFirebaseContext } from '@context/firebaseContext'
import { LoadingSpinner, LoadingSpinnerWrapper } from '@components/shared/LoadingSpinner'
import OrderList from './order-list'
import OrderSearch from '../order-search'

const StyledContainer = styled('div')(() => ({
  '& > :last-child': {
    paddingTop: '20px',
  },
}))

const OrderListWrapper = ({ className }) => {
  const { user } = useFirebaseContext()
  const hasUser = !!user
  const [orderList, setOrderList] = useState(!hasUser ? getFromBrowserStorage('session', 'ordersHistory') : [])
  const hasOrderList = !!orderList?.length
  const activeOrders = Array.isArray(orderList) ? orderList?.filter(orderItem => orderItem.active) : []
  const pastOrders = Array.isArray(orderList) ? orderList?.filter(orderItem => !orderItem.active) : []

  const [userFetchFailed, setUserFetchFailed] = useState(!hasUser)
  const [isLoading, setIsLoading] = useState(false)

  // If user and orderList is NOT populated, fetch by email
  useQuery({
    queryKey: ['orderHistoryByEmail'],
    queryFn: async () => {
      setIsLoading(true)
      await getOrderHistoryByEmail(user.email, user.accessToken)
        .then(data => {
          setOrderList(data)
          setIsLoading(false)
        })
        .catch(() => setUserFetchFailed(true))
        .finally(() => setIsLoading(false))
    },
    enabled: hasUser && !hasOrderList,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  return (
    <div className={`order-status ${className}`} style={{ minHeight: '656px' }}>
      {isLoading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
      {hasOrderList && (
        <StyledContainer className="grid-x cell small-12">
          {activeOrders.length > 0 && <OrderList orderList={activeOrders} heading="Active Orders" />}
          {pastOrders.length > 0 && <OrderList orderList={pastOrders} heading="Past Orders" />}
        </StyledContainer>
      )}
      {userFetchFailed && !isLoading && !hasOrderList && <OrderSearch />}
    </div>
  )
}

OrderListWrapper.propTypes = {
  className: string,
}

export default OrderListWrapper
