import React, { useRef, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import RTGLink from '@shared/link'
import { useFirebaseContext } from '@context/firebaseContext'
import { identifyUser } from '@components/integrations/Segment'
import { getOrdersHistory } from '@services/order-status'
import { validatePhone, validateZip } from '@helpers/string-helper'
import { announce } from '@helpers/aria-announce'
import { orderStatusEvent } from '@helpers/google-tag-manager'
import OrderSearchLegend from './order-search-legend'
import SearchForm from './search-form'
import { OrderSearchWrapper, OrderStatusWrapper, Title, Message, HelpTextWrapper, BackgroundBox } from './styles'

const componentTitle = 'order status'

const componentMessage =
  'Enter your order number and phone number below to pull up your order history for the last 180 days.'

const OrderSearch = () => {
  const orderListRef = useRef(null)
  const [orderId, setOrderId] = useState('')
  const [analyticsOrder, setAnalyticsOrder] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [err, setErr] = useState(null)
  const [loading, setLoading] = useState(false)
  const [zipCode, setZipCode] = useState(null)
  const [authToken, setAuthToken] = useState('')
  const { user } = useFirebaseContext()

  const fetchData = async () => {
    setLoading(true)
    const validPhone = validatePhone(phoneNumber)
    const validZip = validateZip(zipCode)
    if (orderId === '') {
      setErr('Order ID is required')
    } else if (!validPhone) {
      setLoading(false)
      setErr('Phone number is not valid')
    } else if (!validZip) {
      setLoading(false)
      setErr('Zip code is not valid')
    } else if (!authToken) {
      setLoading(false)
      setErr('Please verify you are not a robot')
    } else {
      const query = `?orderId=${orderId}&phoneNumber=${phoneNumber.replace(/[^0-9]/g, '')}&zipcode=${zipCode}`
      try {
        const orderHistory = await getOrdersHistory(query, 10000, authToken)
        if (orderHistory.error) {
          announce('We are unable to retrieve your order, please try again later.')
          setErr(`We are unable to retrieve your order, please try again later.`)
          sessionStorage.setItem('ordersHistory', JSON.stringify([{}]))
        } else {
          sessionStorage.setItem('ordersHistory', JSON.stringify(orderHistory?.rtgOrders) || [])
          setErr(null)
          const [matchingOrder = {}] = orderHistory?.rtgOrders?.filter(order => order?.OrderNumber === orderId)
          setAnalyticsOrder(matchingOrder)
          orderStatusEvent('findOrderSuccess', matchingOrder)
          // TODO: navigation need to add code to track ga data
          navigate(user ? '/account/order/list' : '/order/list')
        }
      } catch {
        announce('We are unable to retrieve your order, please try again later.')
        setErr(`We are unable to retrieve your order, please try again later.`)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    const identify = () => {
      // Segment Identify call, existing identifyUser function should handle both cases -> has/doesn't have AnonymousID
      if (analyticsOrder)
        identifyUser({
          email: analyticsOrder?.EmailAddress,
          phone: phoneNumber,
          signup: false,
          subsourcecode: 'rtgorderstatus',
          zipcode: zipCode,
        })
    }

    identify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsOrder])

  return (
    <OrderSearchWrapper className="grid-x" ref={orderListRef}>
      <OrderStatusWrapper className="cell small-12 medium-8 large-6">
        <Title>{componentTitle}</Title>
        <Message>{componentMessage}</Message>
        <OrderSearchLegend />
        <SearchForm
          orderListRef={orderListRef}
          setOrderId={setOrderId}
          setPhoneNumber={setPhoneNumber}
          setZipCode={setZipCode}
          error={err}
          loading={loading}
          setAuthToken={setAuthToken}
          fetchData={fetchData}
          phoneNumber={phoneNumber}
          zipCode={zipCode}
        />
      </OrderStatusWrapper>
      <HelpTextWrapper className="cell small-12 medium-4 large-6">
        <BackgroundBox className="background-box">
          <h2>Need Help?</h2>
          <p>
            <RTGLink
              data={{
                target: '_blank',
                url: 'https://roomstogo.service-now.com/csp',
                category: 'order search',
                action: 'roomstogo csp click',
                label: 'Request Service',
              }}
            >
              Request Service
            </RTGLink>
          </p>
          <br />
          <p>
            Customer Care Center:
            <br />
            <a href="tel:(800) 766-6786"> (800) 766-6786</a>
          </p>
        </BackgroundBox>
      </HelpTextWrapper>
    </OrderSearchWrapper>
  )
}

export default OrderSearch
